<template>
  <LoadingState v-if="isLoading" />
  <div v-else>
    <a-row>
      <a-col :span="24">
        <h1>Assignment Center</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-slots">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item
              v-for="(slot, index) in menuSlots"
              :key="index"
              @click="showOverview(slot['mata_pelajaran.nama'], slot['kela.tingkat'])"
            >{{slot["kela.tingkat"]}} | {{slot["mata_pelajaran.nama"].toUpperCase()}}</a-menu-item>
          </a-menu>
          <div class="detail-slot mt-4">
            <a-row type="flex" justify="space-between">
              <a-col :xs="12" :lg="6">
                <h3 class="type">Mata Pelajaran</h3>
                <p class="value">{{overviewAssignment.mapel ? overviewAssignment.mapel : '-'}}</p>
              </a-col>
              <a-col :xs="12" :lg="6">
                <h3 class="type">Tingkat</h3>
                <p
                  class="value"
                >{{overviewAssignment.tingkat ? `Tingkat ${overviewAssignment.tingkat} (${overviewAssignment.total_class} Classes)` : '-'}}</p>
              </a-col>
              <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
                <h3 class="type">Session Progress</h3>
                <p class="value" v-if="overviewAssignment.current_week">
                  <span>{{overviewAssignment.current_week}}</span>
                  / {{overviewAssignment.total_week}} Session
                </p>
                <p class="value" v-else>-</p>
              </a-col>
              <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
                <h3 class="type">Assignments</h3>
                <p
                  class="value"
                >{{overviewAssignment.total_assignments ? overviewAssignment.total_assignments : 0}} Topics</p>
              </a-col>
            </a-row>
          </div>
          <div class="divider">
            <a-divider orientation="left">
              <h3>Assignment Process</h3>
            </a-divider>
          </div>
          <a-row :gutter="[32, 32]" class="progress-card" v-if="overviewAips.length">
            <div v-for="(item, index) in overviewAips" :key="index">
              <a-col :sm="24" :md="12" :lg="8">
                <CardProgress :item="item" />
              </a-col>
            </div>
          </a-row>
          <a-row v-else class="my-5">
            <EmptyState :heading="EmptyStateAIP.heading" :description="EmptyStateAIP.description" />
          </a-row>

          <div class="divider">
            <a-divider orientation="left">
              <h3>Assignment & Presences</h3>
            </a-divider>
          </div>
          <div class="table-presences">
            <a-table
              class="table-scroll"
              :columns="columns"
              :data-source="dataOfTableAP"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              bordered
            >
              <a
                slot="Class"
                class="text-primary"
                slot-scope="text, record"
                @click.prevent="toDetailPresences(record.key, record.IdMapel)"
              >{{ text }}</a>
              <span slot="ClassPresences" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{text}} %</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{text}} %</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{text}} %</a-tag>
                </div>
              </span>

              <span slot="AvgGrade" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{text}}</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{text}}</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{text}}</a-tag>
                </div>
              </span>
              <span slot="Graded" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{text}} %</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{text}} %</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{text}} %</a-tag>
                </div>
              </span>
            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import CardProgress from '@/components/app/Assignment/CardAssignment'
import EmptyState from '@/components/app/EmptyState'
import LoadingState from '@/components/app/LoadingState'
const columns = [
  {
    title: 'Class',
    dataIndex: 'Class',
    key: 'Class',
    // width: '25%',
    // width: 200,
    scopedSlots: { customRender: 'Class' },
  },
  {
    title: 'Students',
    key: 'Students',
    dataIndex: 'Students',
    // width: '10%',
    // width: 200,
    scopedSlots: { customRender: 'Students' },
  },
  {
    title: 'Class Presences (%)',
    key: 'ClassPresences',
    dataIndex: 'ClassPresences',
    // width: '12%',
    // width: 200,
    scopedSlots: { customRender: 'ClassPresences' },
  },
  {
    title: 'Finished Assignment',
    children: [
      {
        title: 'Amount',
        dataIndex: 'AmountFinished',
        key: 'AmountFinished',
        // width: 100,
        // width: 200,
        scopedSlots: { customRender: 'AmountFinished' },
      },
      {
        title: 'Avg. Grade',
        dataIndex: 'AvgGrade',
        key: 'AvgGrade',
        // width: 80,
        // width: 200,
        scopedSlots: { customRender: 'AvgGrade' },
      },
    ],
  },
  {
    title: 'Assignments in Progress',
    children: [
      {
        title: 'Amount',
        dataIndex: 'AmountProgress',
        key: 'AmountProgress',
        // width: 100,
        // width: 200,
        scopedSlots: { customRender: 'AmountProgress' },
      },
      {
        title: 'Submitted',
        dataIndex: 'Submitted',
        key: 'Submitted',
        // width: 100,
        // width: 200,
        scopedSlots: { customRender: 'Submitted' },
      },
      {
        title: 'Graded (%)',
        dataIndex: 'Graded',
        key: 'Graded',
        // width: 100,
        // width: 200,
        scopedSlots: { customRender: 'Graded' },
      },
    ],
  },
]

const EmptyStateAIP = {
  heading: 'No Data',
  description: 'You have no assignment in progress',
}
export default {
  data() {
    return {
      current: [0],
      columns,
      EmptyStateAIP,
    }
  },
  components: {
    LoadingState,
    CardProgress,
    EmptyState,
  },
  methods: {
    toDetailPresences(id, idMapel) {
      this.$router.push({ name: 'Presences Assignments Teacher', params: { id, idMapel } })
    },
    showOverview(mapel, tingkat) {
      this.mapel = mapel
      this.tingkat = tingkat
      this.$store.dispatch('slot/FETCH_OVERVIEW_ASSIGNMENT_TEACHER', { idGuru: this.user.id, namaMapel: this.mapel, tingkat: this.tingkat })
      this.$store.dispatch('slot/FETCH_OVERVIEW_AIP_TEACHER', { idGuru: this.user.id, namaMapel: this.mapel, tingkat: this.tingkat })
    },
  },
  created() {
    // this.$store.dispatch('slot/FETCH_ASSIGNMENT_GRADING', { idGuru: this.user.id, idMataPelajaran: 1, idKelas: 1 })
    this.$store.dispatch('slot/FETCH_MENU_SLOT_TEACHER', { idGuru: this.user.id })
      .then(data => {
        if (data[0]) {
          this.$store.dispatch('slot/FETCH_OVERVIEW_ASSIGNMENT_TEACHER', { idGuru: this.user.id, namaMapel: data[0]['mata_pelajaran.nama'], tingkat: data[0]['kela.tingkat'] })
          this.$store.dispatch('slot/FETCH_OVERVIEW_AIP_TEACHER', { idGuru: this.user.id, namaMapel: data[0]['mata_pelajaran.nama'], tingkat: data[0]['kela.tingkat'] })
        } else {
          this.$store.commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
        }
      })
  },
  computed: {
    // sesiGradings() {
    //   return this.$store.state.slot.sesiGradings
    // },
    isLoading() {
      return this.$store.state.isLoading
    },
    user() {
      return this.$store.state.user.user
    },
    menuSlots() {
      return this.$store.state.slot.menuSlots
    },
    overviewAssignment() {
      return this.$store.state.slot.overviewAssignment
    },
    overviewAips() {
      return this.$store.state.slot.overviewAips
    },
    dataOfTableAP() {
      return this.$store.state.slot.dataOfTableAP
    },
  },
}
</script>

<style lang="scss">
.card-slots {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail-slot {
  padding: 40px 20px;
}

.detail-slot .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail-slot .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}

.detail-slot .value span {
  color: #1b90ff;
}

.progress-card {
  padding: 10px 20px;
}

.table-presences {
  padding: 10px 20px 20px 20px;
}

.table-scroll {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
  }
}
</style>
